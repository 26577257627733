import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import atasozleriData from './data/atasozleri.json';

function App() {
  const [atasozleri, setatasozleri] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [options, setOptions] = useState([]);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [isReviewMode, setIsReviewMode] = useState(false);

  useEffect(() => {
    startNewTest();
  }, []);

  useEffect(() => {
    if (atasozleri.length > 0 && currentQuestion < atasozleri.length) {
      generateOptions();
    }
  }, [currentQuestion, atasozleri]);

  const startNewTest = () => {
    const shuffled = [...atasozleriData].sort(() => 0.5 - Math.random());
    setatasozleri(shuffled.slice(0, 10));
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setWrongAnswers([]);
    setIsReviewMode(false);
  };

  const generateOptions = () => {
    const correctAnswer = atasozleri[currentQuestion].anlam;
    const otherOptions = atasozleriData
      .filter(item => item.anlam !== correctAnswer)
      .map(item => item.anlam)
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);
    
    const allOptions = [...otherOptions, correctAnswer]
      .sort(() => 0.5 - Math.random());
    
    setOptions(allOptions);
  };

  const handleAnswerClick = (selectedAnswer) => {
    const correct = selectedAnswer === atasozleri[currentQuestion].anlam;
    setAnswerStatus(correct);
    
    if (correct) {
      setScore(score + 1);
    } else {
      setWrongAnswers([...wrongAnswers, atasozleri[currentQuestion]]);
    }
    
    setTimeout(() => {
      setAnswerStatus(null);
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < atasozleri.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowScore(true);
      }
    }, 2000);
  };

  const handleReviewWrong = () => {
    setatasozleri(wrongAnswers);
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setWrongAnswers([]);
    setIsReviewMode(true);
  };

  const shareOnSocialMedia = (platform) => {
    const text = `Atasözleri Testi'nde ${score} soruyu doğru cevapladım! Sen de kendini test et!`;
    const url = window.location.href;
    let shareUrl;

    switch(platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`;
        break;
      case 'instagram':
        alert("Instagram'da paylaşmak için, lütfen Instagram uygulamasını açın ve ekran görüntüsü paylaşın.");
        return;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  const pageTitle = showScore ? "Test Sonucu - Atasözleri Testi" : "Atasözleri Testi";
  const pageDescription = "Türkçe Atasözleri hakkında bilginizi test edin. Kısa testlerle Atasözlerinin anlamlarını tahmin edin.";

  if (atasozleri.length === 0) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="atasozleri, Türkçe atasozleri, atasozü öğreniyorum , atasozü testi, Türkçe quiz, LGS, Liselere Giriş Sınavı" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div style={{ 
        maxWidth: '600px', 
        margin: '0 auto', 
        padding: '20px', 
        fontFamily: 'Arial, sans-serif' 
      }}>
        <h1 style={{ textAlign: 'center' }}>Atasözleri Testi</h1>
        {showScore ? (
          <div style={{ textAlign: 'center' }}>
            <h2>Testin sonu!</h2>
            <p>{isReviewMode ? 'Yanlış cevaplanan sorular tekrarı' : '10 sorudan'} {score} tanesini doğru bildiniz.</p>
            {!isReviewMode && (
              <div style={{ marginTop: '20px' }}>
                <p>Sonucunu paylaş:</p>
                {['twitter', 'facebook', 'whatsapp', 'instagram'].map((platform) => (
                  <button 
                    key={platform}
                    onClick={() => shareOnSocialMedia(platform)} 
                    style={{
                      padding: '10px',
                      margin: '5px',
                      backgroundColor: platform === 'twitter' ? '#1DA1F2' : 
                                       platform === 'facebook' ? '#4267B2' : 
                                       platform === 'whatsapp' ? '#25D366' : '#E1306C',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </button>
                ))}
              </div>
            )}
            {!isReviewMode && wrongAnswers.length > 0 && (
              <button 
                onClick={handleReviewWrong}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#FFA500',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '20px',
                  marginRight: '10px'
                }}
              >
                Yanlış Yapılanları Tekrar Dene
              </button>
            )}
            <button 
              onClick={startNewTest}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '20px'
              }}
            >
              Yeni Test Başlat
            </button>
          </div>
        ) : (
          <>
            <p style={{ fontSize: '18px', marginBottom: '20px' }}>
              "{atasozleri[currentQuestion].atasozu}" atasözünün anlamı nedir?
            </p>
            <div>
              {options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerClick(option)}
                  style={{
                    display: 'block',
                    width: '100%',
                    padding: '10px',
                    marginBottom: '10px',
                    fontSize: '16px',
                    backgroundColor: '#f0f0f0',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                  disabled={answerStatus !== null}
                >
                  {option}
                </button>
              ))}
            </div>
            {answerStatus !== null && (
              <div style={{
                marginTop: '20px',
                padding: '10px',
                backgroundColor: answerStatus ? '#d4edda' : '#f8d7da',
                borderRadius: '5px'
              }}>
                <h3>{answerStatus ? 'Doğru!' : 'Yanlış!'}</h3>
                <p>
                  {answerStatus 
                    ? 'Tebrikler, doğru cevabı verdiniz.' 
                    : `Doğru cevap: ${atasozleri[currentQuestion].anlam}`}
                </p>
              </div>
            )}
            <p style={{ textAlign: 'right', marginTop: '20px' }}>
              Soru {currentQuestion + 1} / {atasozleri.length}
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default App;